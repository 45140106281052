import React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import defaultRTRenderOptions from "../../contentful/rich-text-renderer-options";
import Section from "../components/section/section";
import Img from "gatsby-image/withIEPolyfill";
import {graphql, useStaticQuery} from "gatsby";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import ReviewStars from "../components/review-stars/review-stars";
import TrustBox from "../components/trustpilot";
import {Accordion as BootstrapAccordion} from "react-bootstrap";
import styles from '../scss/page.scss';
import SEO from "../components/seo";
import {get} from "lodash";
import {authorUrl, buildBreadcrumbsItem, postUrl} from "../helpers";


class CanadaPage extends React.Component {
  constructor(props) {
    super(props);
    this.author = {
      name: 'Prairie Conlon',
      title: 'LPC, NCC'
    }
    this.cities = [
      {
        title: 'British Columbia',
        description: 'If you\'re a British Columbia resident, then you and your ESA are protected by the Guide Dog and Service Dog Act. This piece of legislation doesn’t specifically apply to emotional support dogs. However, the arguments made can be used to justify the presence of your ESA.',
        eventKey: 0,
      },
      {
        title: 'Ontario',
        description: 'If you call Ontario your home, the Accessibilities for Ontarians with Disabilities Act and the Human Rights Code are the ones to look up to. These acts have all the legal information you need to be aware of.',
        eventKey: 1,
      },
      {
        title: 'Quebec',
        description: 'Since Quebec laws are somewhat murky, it’s incredibly important to know them well! First, the “Act to secure handicapped persons in the exercise of their rights with a view to achieving social, school and workplace integration.” Second, the Charter of Human Rights and Freedoms. They protect you when looking for a place to rent.',
        eventKey: 2,
      },
      {
        title: 'Saskatchewan',
        description: 'In Saskatchewan, residents with ESAs are protected by the Saskatchewan Human Rights Code. A “no pets” policy in rental or condominium housing does not apply to those residents who have medical evidence that states their need for the animal to alleviate symptoms of a mental disorder.',
        eventKey: 3,
      },
      {
        title: 'Alberta',
        description: 'The Service Animals Act is the law you need to know if you live in Alberta. Although it doesn’t say anything in particular about ESAs, the arguments and base for legal defense are the same as for service animals.',
        eventKey: 4,
      },
      {
        title: 'Manitoba',
        description: 'Manitoba residents can also refer to the Human Rights Code and to the Service Animals Protection Act for help. They protect all people with mental disabilities from being discriminated against when it comes to housing.',
        eventKey: 5,
      },
      {
        title: 'Other Provinces',
        description: 'If you live in New Brunswick, Newfoundland and Labrador, Nova Scotia, Nunavut, Prince Edward Island, or Yukon, you are protected by the Human Rights Act. Section 5 of this act protect mentally ill people from being discriminated against when renting commercial and dwelling units. This applies even to buildings that have a no-pets policy!',
        eventKey: 6,
      },
    ]
    this.itemsRefs = {}
    this.cities.map(( item, index ) => {
      this.itemsRefs[item.eventKey] = React.createRef();
    })
    this.breadcrumbs = [
      buildBreadcrumbsItem('Emotional Support Animal Canada Laws', this.props.location.href)
    ]
    this.toggleHeaderClass = this.toggleHeaderClass.bind(this);
    this.seo = {
      title: 'Canada Laws and Guidance for Emotional Support Animals',
      description:  'Do you need to register your emotional support animal in Canada? What Canadian laws do you need to follow? Learn all about ESAs in Canada here.',
      seoTitle: 'Canada Laws and Guidance for Emotional Support Animals'
    }
    this.post = {
      slug: 'canada-register-emotional-support-animal',
    }

  }

  toggleHeaderClass(index) {
    if (this.itemsRefs) {
      for (let f in this.itemsRefs) {
        if ((index == f) || this.itemsRefs[f].current.classList.contains('open')) {
          this.itemsRefs[f].current.classList.toggle('open')
        }
      }
    }
  }
  render(){


    const defaultImage = get(this.props, 'data.defaultImage.childImageSharp.fluid')
    const publisherName = get(this.props, 'data.site.siteMetadata.publisher.name')
    const url = postUrl(this.post, get(this.props, 'data.site.siteMetadata.siteUrl'))

    const { data } = this.props
    const options = {
      ...defaultRTRenderOptions
    }
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    return(
      <>
        <Layout containerClassNames="page-layout canada" breadcrumbs={this.breadcrumbs} location={this.props.location}>
          <SEO { ...this.seo } />

          <article className="post-content-container" itemScope itemType="https://schema.org/BlogPosting">
            <meta itemProp="url" content={ url } />
            <link itemProp="mainEntityOfPage" href={ url } />
            <meta itemProp="datePublished" content="2020-12-10T17:51:29" />
            <meta itemProp="dateModified" content="2020-12-11T17:51:10" />
            <meta itemProp="headline" content="Canada Laws and Guidance for Emotional Support Animals" />
            { publisherName ? (
              <link itemProp="publisher" href={ `#${publisherName.replace(' ', '')}Org` } />
            ) : ''}
            { this.author ? (
              <div itemProp="author" itemScope itemType="https://schema.org/Person">
                <meta itemProp="name" content={ this.author.name } />
                <meta itemProp="honorificSuffix" content={ this.author.title } />
              </div>
            ) : '' }
            <div className="post-content" itemProp="articleBody">


              <Section className="section header-section px-0">
                <div className="header-section">
                  <div className="container">
                    <div className="row">
                      <div className="col-10 offset-1 title"><h1 className="mt-3 mt-md-2 pt-md-3">Emotional Support Animal Canada Laws</h1></div>
                    </div>
                  </div>
                </div>
              </Section>

              <Section className="section-one px-1">
                <div className="row">
                  <div className="blog-data mt-5 pt-4 mx-auto text-center">
                    Reviewed by: <a href={authorUrl(this.author)} rel="author" className="blog-author">{this.author.name}, {this.author.title}</a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10 col-md-6 mx-auto mt-3">
                    <div className="first-content mt-5">
                      <p>Emotional support animals in Canada are largely unrepresented in Canadian law. Apart from travel laws, most rules and regulations surrounding ESAs differs from one Canadian province to the next. If you are thinking about getting an emotional support animal in Canada, you need to be sure to research both federal and provincial laws.</p>
                      <br/>
                      <p>Firstly, there are federal laws in place that let you fly with your ESA. The Canadian Transportation Agency grants you this right. It also forces all airlines to comply with the Air Transportation Regulations. No matter where you’re seated, your ESA will be accepted onboard free of charge.</p>
                      <br/>
                      <p>When it comes to housing, ESAs are also protected by laws. But these laws are not federal, they’re made by each of the provinces. This is very important because depending on where you live, housing laws may vary.</p>
                      <br/>
                      <p>Always keep in mind that these rules will only protect you and your ESA if you have a valid emotional support letter. The person or agency that writes this letter to you has to be in good standing with the law. This means that they have to comply with the Canada Health Act.</p>
                    </div>
                    <div className="second-content">
                      <div className="row align-items-center">
                        <div className="col-12 col-md-6 mt-5">
                          <Img fluid={data.womanWithHerEsa.childImageSharp.fluid} alt="Woman With Her Esa" className="img-fluid lazyload" />
                        </div>
                        <div className="col-12 col-md-6 mt-5">
                  <span>
                    No matter where<br className='d-md-none'/>
                    you’re seated, <br className='d-md-none'/>
                    your ESA will be <br className='d-md-none'/>
                    accepted onboard free of charge.</span>
                        </div>
                        <div className="row ml-0 mr-0">
                          <div className="col-12 col-md-12 mt-5 mb-5 content">
                            <p>Similar to the Air Carrier Access Act (ACAA) in America, the Aircraft Accessibility for Persons with Disabilities allows a person with a physical/mental/emotional disorder to fly with protections. People suffering from mental or physical disabilities can fly with their assistance animal free of charge. A disabled person suffering from mental disorders or illnesses such as post-traumatic stress disorder (PTSD), anxiety or depression massively benefit from the calming and comforting presence of an assistance animal in public places.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 col-md-12 offset-lg-6 mb-5">
                    <div className="call-button my-lg-5">
                      <a href={`${ctaUrl}&utm_campaign=esacanadav2&utm_content=cta1`} target="_blank">START SCREENING PROCESS</a>
                    </div>
                  </div>
                </div>
              </Section>

              <Section bgColor="#e0f0f0" className="bgsection px-1">
                <div className="row justify-content-center">
                  <div className="col-10 col-md-6">
                    <div className="block pt-5 pb-5">
                      <div className="bgsection-img mb-3">
                        <Img fluid={data.housing.childImageSharp.fluid} alt="Housing" className="img-fluid lazyload" />
                      </div>
                      <div className="title mb-5">Housing Emotional Support Animal Letter Canada</div>
                      <div className="content">
                        <p>In Canada, housing laws that protect ESAs can be tricky. The best way to ensure your province provides protection is by looking at how that province's law defines ESAs. You also need to find out whether there is a specific act that protects persons with disabilities that include emotional or mental illnesses or conditions.</p>
                        <br/>
                        <p>Most provinces seem to make room for fair housing for people with disabilities. In provinces where people with mental illnesses are protected by law, a landlord must provide them with reasonable accommodation despite there being a “no-pet” policy in place. The landlord is also not allowed to charge an extra pet fee or deposit. However, according to Canadian law, the owner of an assistance animal is must pay for any damages to the property caused by the animal.</p>
                      </div>
                    </div>

                    <div className="block pt-5 pb-5">
                      <div className="bgsection-img mb-3">
                        <Img fluid={data.travel.childImageSharp.fluid} alt="Travel" className="img-fluid lazyload" />
                      </div>
                      <div className="title mb-5">Travel Emotional Support Animal Letter Canada</div>
                      <div className="content">
                        <p>An emotional support letter is official proof that your companion is, in fact, an ESA. This means that they're not your pet, and so are protected by some Canadian laws. Emotional support letters are used to separate pets being passed off as ESAs from actual ESAs.</p>
                        <br/>
                        <p>But how can you get it? Writing an emotional support letter in Canada isn’t something you, the ESA owner, can do. You have to ask a licensed mental health professional who can practice in your province to write it for you. Your therapist will assess your mental state and then write the letter if they think you can benefit from having an ESA.</p>
                        <br/>
                        <p>Gone are the days when you needed to look for a licensed mental health professional for hours on the phone book. Therapetic, powered by CertaPet, now simplifies the process for you. Our team of licensed mental health professionals is more than qualified to issue your emotional support letter. With it, you’ll have access to all the privileges we mentioned before!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Section>

              <Section className="section-second px-1">
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 px-0">
                    <Img fluid={data.youngWomanWithHerEsaInArms.childImageSharp.fluid} alt="Young Woman With Her Esa In Arms" className="lazyload img-fluid" />
                  </div>
                  <div className="col-10 offset-1 offset-md-0 col-md-6 mb-5">
                    <h2 className="title">Legitimate ESA Registration in Canada</h2>
                    <div className="content">
                      <p>Don't be fooled by fake sites! The so-called emotional support animal registration AND certification in Canada does not exist.</p>
                      <br/>
                      <p>No law requires you to register your ESA or to get them certified. Be sure to stay away from any company trying to sell you an emotional support animal certification in Canada.</p>
                      <br/>
                      <p>To get an emotional support animal in Canada, all you need is an emotional support letter issued by a licensed mental health professional. This letter will disclose all the information about you as the owner and why you need an emotional support animal.</p>
                    </div>
                  </div>
                </div>
              </Section>

              <Section className="section-common-questions px-1">
                <div className="container">
                  <div className="common-questions row">
                    <div className="col-10 offset-1 offset-md-0 col-md-12">
                      <h2 className="title">Common Questions</h2>
                      <div className="row">
                        <div className="col-12 col-md-6 text-center first-card">
                          <Card>
                            <Card.Header className="rounded-circle">
                              <Img fluid={data.question.childImageSharp.fluid} alt="Common Question" className="img-fluid lazyload" />
                            </Card.Header>
                            <Card.Body>
                              <Card.Title as="h3">Is CertaPet Legit?</Card.Title>
                              <Card.Text>
                                <p>Certapet's main goal is to spread the knowledge on<br/>
                                  emotional support animals and help those in need get<br/>
                                  the care they deserve. That's why they're dedicated to<br/>
                                  connecting qualified individuals to licensed mental health<br/>
                                  professionals in their state.</p>
                                <br/>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-12 col-md-6 text-center">
                          <Card>
                            <Card.Header className="rounded-circle">
                              <Img fluid={data.question.childImageSharp.fluid} alt="Common Question" className="img-fluid lazyload" />
                            </Card.Header>
                            <Card.Body>
                              <Card.Title as="h3">Is Therapetic Legit?</Card.Title>
                              <Card.Text>
                                <p>The information and documentation we issue is 100%<br/>
                                  compliant with federal and state laws. Your personalized<br/>
                                  letter is issued by a real licensed mental health<br/>
                                  professional in your state or province. In fact, each letter<br/>
                                  contains the documentation where the board can be contacted and verified.<br/>
                                  <br/>
                                  Want to know if you are eligible<br/>
                                  to become an ESA owner? TheraPetic, powered by<br/>
                                  Certapet, can help you throughout the entire process<br/>
                                  thanks to our fully qualified licensed mental health<br/>
                                  professionals.</p>
                                <br/>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 col-md-12 my-5 text-center">
                    <div className="call-button my-lg-5">
                      <a href={`${ctaUrl}&utm_campaign=esacanadav2&utm_content=cta2`} target="_blank">START SCREENING PROCESS</a>
                    </div>
                  </div>
                </div>
                {/*<div className="row justify-content-center">*/}
                {/*<div className="col-10 col-md-12 offset-lg-9 mb-5">*/}
                {/*<a href={`${ctaUrl}&utm_campaign=esacanadav2&utm_content=cta2`} target="_blank" className="my-4 mx-auto btn-cta text-center">START SCREENING PROCESS</a>*/}
                {/*</div>*/}
                {/*</div>*/}
              </Section>

              <Section className="review px-1">
                <div className="row">
                  <div className="offset-1"></div>
                  <div className="col-10 mt-5">
                    <div className="row desktop">
                      <Carousel>
                        <Carousel.Item>
                          <div className="row">
                            <div className="col-12 col-md-4 text-center mt-3">
                              <Card>
                                <Card.Header>
                                  <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon"/>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Text>
                                    The entire process was very efficient and less time-consuming. My therapist did a great job in assisting me with my case and explaining the details.
                                  </Card.Text>
                                  <ReviewStars stars={5} color="#2dbecc"/>
                                </Card.Body>
                              </Card>
                            </div>
                            <div className="col-12 col-md-4 text-center mt-3">
                              <Card>
                                <Card.Header>
                                  <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon" className="img-fluid lazyload" />
                                </Card.Header>
                                <Card.Body>
                                  <Card.Text>
                                    I couldn’t be happier with the customer service of CertaPet. They were very considerate and thorough in their evaluation and timely on getting me the paperwork required. I can’t thank you enough!
                                  </Card.Text>
                                  <ReviewStars stars={5} color="#2dbecc"/>
                                </Card.Body>
                              </Card>
                            </div>
                            <div className="col-12 col-md-4 text-center mt-3">
                              <Card>
                                <Card.Header>
                                  <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon" className="img-fluid lazyload" />
                                </Card.Header>
                                <Card.Body>
                                  <Card.Text>
                                    The process was extremely easy, fast and convenient. Once approved I had my letter in a couple of days in my mailbox and same day in my email. I’m soo glad I have my pet as an emotional support animal. Now I don’t have to worry about being separated from him ever!
                                  </Card.Text>
                                  <ReviewStars stars={5} color="#2dbecc" />
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                    <div className="row mobile">
                      <Carousel>
                        <Carousel.Item>
                          <div className="row">
                            <div className="col-12 col-md-4 text-center mt-3">
                              <Card>
                                <Card.Header>
                                  <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon"/>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Text>
                                    The entire process was very efficient and less time-consuming. My therapist did a great job in assisting me with my case and explaining the details.
                                  </Card.Text>
                                  <ReviewStars stars={5} color="#2dbecc"/>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Carousel.Item>
                        <Carousel.Item>
                          <div className="row">
                            <div className="col-12 col-md-4 text-center mt-3">
                              <Card>
                                <Card.Header>
                                  <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon" className="img-fluid lazyload" />
                                </Card.Header>
                                <Card.Body>
                                  <Card.Text>
                                    I couldn’t be happier with the customer service of CertaPet. They were very considerate and thorough in their evaluation and timely on getting me the paperwork required. I can’t thank you enough!
                                  </Card.Text>
                                  <ReviewStars stars={5} color="#2dbecc"/>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Carousel.Item>
                        <Carousel.Item>
                          <div className="row">
                            <div className="col-12 col-md-4 text-center mt-3">
                              <Card>
                                <Card.Header>
                                  <Img fluid={data.blockquote.childImageSharp.fluid} alt="Review Icon" className="img-fluid lazyload" />
                                </Card.Header>
                                <Card.Body>
                                  <Card.Text>
                                    The process was extremely easy, fast and convenient. Once approved I had my letter in a couple of days in my mailbox and same day in my email. I’m soo glad I have my pet as an emotional support animal. Now I don’t have to worry about being separated from him ever!
                                  </Card.Text>
                                  <ReviewStars stars={5} color="#2dbecc"/>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div>

                    <div className="container">
                      <div className="row justify-content-center pt-5 mt-5">
                        <div className="col-12 col-md-3 mb-4 mt-5 ml-lg-2 ml-5">
                          <div className="read-reviews">
                            <a href="https://www.trustpilot.com/review/therapetic.org?utm_medium=trustbox&utm_source=MicroReviewCount" target="_blank">READ ALL REVIEWS</a>
                          </div>
                        </div>
                        <div className="col-12 pb-5">
                          <TrustBox templateId="5419b6a8b0d04a076446a9ad" businessUnitId="5a0093cb0000ff0005afb51b" theme="light" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section>

              <Section className="section-accordion px-0">
                <div className="container container-accordion">
                  <div className="row pt-5">
                    <div className="col-10 offset-1 col-md-12 offset-md-0">
                      <h2 className="title mb-5">Content ESA Laws in Your Province</h2>
                      <p className="text">Luckily, you may be able to live with your emotional support animal in Canada. Different laws apply to each province. But they all have one thing in common: they all shelter people with mental disabilities from discrimination. This can also be interpreted to apply to housing, and there are already hundreds of people living with their ESAs.</p>
                    </div>
                  </div>
                </div>
                <BootstrapAccordion>
                  {this.cities.map((item, index) => {
                    return (
                      <Card>
                        <Card.Header id={`accordion-header-${index}`} ref={ this.itemsRefs[index] } >
                          <BootstrapAccordion.Toggle as="h3" eventKey={index} onClick={this.toggleHeaderClass.bind(this, index)}>
                            {item.title}
                            <p className="icon float-right"/>
                          </BootstrapAccordion.Toggle>
                        </Card.Header>
                        <BootstrapAccordion.Collapse eventKey={index}>
                          <Card.Body>
                            {item.description}
                          </Card.Body>
                        </BootstrapAccordion.Collapse>
                      </Card>
                    )
                  })}
                </BootstrapAccordion>
              </Section>


            </div>
            <meta itemProp="image" content={ defaultImage.src } />
          </article>

        </Layout>
      </>
    )
  }
}


export default (props) => {
  const data = useStaticQuery(graphql`
    query CanadaPage {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
          siteUrl
          publisher {
            name
          }
        }
      } 
      womanWithHerEsa: file(relativePath: { eq: "page_layout/woman-with-her-esa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 315, maxHeight: 341) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      housing: file(relativePath: { eq: "page_layout/icons/housing-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 161, maxHeight: 154) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      travel: file(relativePath: { eq: "page_layout/icons/travel-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 161, maxHeight: 154) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      youngWomanWithHerEsaInArms: file(relativePath: { eq: "page_layout/young-woman-with-her-esa-in-arms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 682, maxHeight: 622) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      question: file(relativePath: { eq: "page_layout/icons/ico-question.png" }) {
        childImageSharp {
          fluid(maxWidth: 682, maxHeight: 622) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blockquote: file(relativePath: { eq: "page_layout/icons/ico-blockquotes.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, maxHeight: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      defaultImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <CanadaPage  {...props} data={data}/>
  )
};
